<template>
  <div class="flightbooking_form_rightbody">
    <div class="heading">
      <h3>
        <div class="d-flex align-items-center">
          <span>
            <i class="zmdi zmdi-accounts-alt"></i>
          </span>
          <strong>{{$t("sabre.booking.passenger-details")}}</strong>
        </div> {{$t("sabre.booking.passenger-box.name-record-notice")}}
      </h3>
    </div>
    <div class="flightformbox">

      <passenger-one-form :disabled="disabled" :index="{inx, uKey: inx}"
      v-for="(adult, inx) in adults" :key="inx" passengerType="adult" @update="update" :babiesCount="babies"/>

      <passenger-one-form :disabled="disabled" :index="{inx, uKey: inx+adults}"
      v-for="(child, inx) in children+diffBabies" :key="inx+adults" passengerType="child" @update="update"/>

      <!-- <passenger-one-form :disabled="disabled" :index="{inx, uKey: inx+adults+children}"
      v-for="(baby, inx) in diffBabies" :key="inx+adults+children" passengerType="infant" @update="update"/> -->

      <passenger-one-form :disabled="disabled" :index="{inx, uKey: inx+adults+children+diffBabies}"
      v-for="(senior, inx) in seniors" :key="inx+adults+children+diffBabies" passengerType="senior" @update="update"/>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'passenger-detail-form',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PassengerOneForm: () => import('./passengerOneForm'),
  },
  computed: {
    ...mapGetters({
      flight: 'GET_SABRE_ORDERED_FLIGHT',
      condition: 'GET_SABRE_ORDERED_CONDITION',
    }),
    adults() {
      const { condition } = this;
      return condition.passengerComposition.adults;
    },
    children() {
      const { condition } = this;
      return condition.passengerComposition.children;
    },
    babies() {
      const { condition } = this;
      return condition.passengerComposition.infants;
    },
    diffBabies() {
      const { adults, babies } = this;
      const diffCount = babies - adults;
      return diffCount < 0 ? 0 : diffCount;
    },
    seniors() {
      const { condition } = this;
      return condition.passengerComposition.seniors;
    },
  },
  data() {
    return {
      passengers: [],
    };
  },
  methods: {
    update(data) {
      const { id } = data;
      this.passengers[id] = data;
      this.$emit('update', { type: 'passenger', data: this.passengers });
    },
  },
};
</script>
